<template>
  <v-list dense>
    <v-list-item
      v-for="(item, index) in items"
      :key="index"
      :href="item.url"
      target="_blank"
    >
      <ListIcon>
        <v-icon>{{ item.icon }}</v-icon>
      </ListIcon>
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "LinksModule",

  data() {
    return {
      items: [
        {
          title: "Cloud",
          subtitle: "Office365, OneDrive, Teams, Outlook, ...",
          icon: "mdi-cloud-outline",
          url: "https://cloud.gymkirchenfeld.ch",
        },
        {
          title: "Outlook",
          subtitle: "E-Mail und Kalender",
          icon: "mdi-email-outline",
          url: "https://outlook.office.com/owa/",
        },
        {
          title: "gymkirchenfeld.ch",
          subtitle: "Öffentliche Gymer-Webseite",
          icon: "mdi-link",
          url: "https://www.gymkirchenfeld.ch/",
        },
      ],
    };
  },
};
</script>
